







































































































































import SelectMasterType from "@/components/custom/select/SelectMasterType.vue";
import { DisplayBoolean } from "@/components/DisplayBoolean";
import {
  useFindMasterType,
  useLeasing,
  useMapMasterTypeToOptionAlt,
} from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { ONE } from "@/models/constant/global.constant";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { LeasingStateEnum } from "@/models/enums/Leasing.enum";
import { PaymentRealizationCreateDto } from "@/models/interface/leasing";
import { FormValue, RowPeriod, State } from "@/store/leasing.store";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { Component, Mixins } from "vue-property-decorator";
import { mapActions, mapMutations, mapState } from "vuex";

@Component({
  components: {
    SelectMasterType,
    DisplayBoolean,
  },
  computed: {
    ...mapState({
      store: (st: any) => st.leasingStore,
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
  },
  methods: {
    ...mapMutations({
      setForm: "leasingStore/setForm",
    }),
    ...mapActions({
      generatePeriod: "leasingStore/generatePeriod",
      fetchDetail: "leasingStore/fetchDetail",
    }),
  },
})
export default class FormPayment extends Mixins(MNotificationVue) {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  reverseFormatNumber = reverseFormatNumber;
  formatterNumber = formatterNumber;

  storeBaseDecimalPlace!: number;
  store!: State;
  generatePeriod!: () => void;
  fetchDetail!: (leasingId: string) => void;
  setForm!: (value: Partial<FormValue>) => void;
  paymentTypeOptions: Option[] = [];

  columns = [
    {
      title: this.$t("lbl_billing_period"),
      dataIndex: "installment",
      fixed: "left",
      width: "150px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_payment_number"),
      dataIndex: "chequeNumber",
      fixed: "left",
      width: "200px",
      scopedSlots: { customRender: "chequeNumber" },
    },
    {
      title: this.$t("lbl_payment_type"),
      dataIndex: "paymentType",
      scopedSlots: { customRender: "paymentType" },
    },
    {
      title: this.$t("lbl_payment_date"),
      dataIndex: "paymentDate",
      scopedSlots: { customRender: "paymentDate" },
    },
    {
      title: this.$t("lbl_principal_cost"),
      dataIndex: "principalCost",
      scopedSlots: { customRender: "principalCost" },
    },
    {
      title: this.$t("lbl_interest_cost"),
      dataIndex: "interestCost",
      scopedSlots: { customRender: "interestCost" },
    },
    {
      title: this.$t("lbl_installment"),
      dataIndex: "paymentCost",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_balance"),
      dataIndex: "balance",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_realization"),
      dataIndex: "paymentRealizationDate",
      scopedSlots: { customRender: "paymentRealizationDate" },
    },
    {
      title: this.$t("lbl_document_reference"),
      dataIndex: "cashInOutDocumentNumber",
      scopedSlots: { customRender: "cashInOutDocumentNumber" },
    },
    {
      key: "operation",
      align: "center",
      scopedSlots: { customRender: "operation" },
    },
  ];

  calcPaymentAndBalance(row: RowPeriod): void {
    const { calcPaymentCost, calcPaymentFirstBalance, calcPaymentBalance } =
      useLeasing();
    const FIRST_PERIOD = 1;

    row.paymentCost = calcPaymentCost({
      principalCost: row.principalCost,
      interestCost: row.interestCost,
    });

    let prevBalance = 0;
    if (row.installment > FIRST_PERIOD) {
      const currentIdx: number = this.store.form.periodList.findIndex(
        e => e.installment === row.installment
      );
      const prevIdx = currentIdx - ONE;
      prevBalance = this.store.form.periodList[prevIdx].balance;
    }

    row.balance = calcPaymentBalance({
      prevBalance,
      paymentCost: row.paymentCost,
    });

    if (row.installment === FIRST_PERIOD) {
      row.balance = calcPaymentFirstBalance({
        paymentCost: row.paymentCost,
        totalInstallment: this.store.form.total,
      });
    }
  }

  handleCancelPayment(row: RowPeriod): void {
    const { cancelPayment } = useLeasing();
    row.loading.cancel = true;
    cancelPayment(row.id)
      .then(({ documentNo }) => {
        this.showNotifSuccess("notif_cancel_success", {
          documentNumber: documentNo,
        });
        this.fetchDetail(this.store.form.id);
      })
      .finally(() => {
        row.loading.cancel = false;
      });
  }

  handleCreatePayment(row: RowPeriod): void {
    const { settlePayment, mapPeriodLineToSettlePaymentDto } = useLeasing();
    const req: PaymentRealizationCreateDto =
      mapPeriodLineToSettlePaymentDto(row);
    row.loading.settle = true;
    settlePayment(row.id, req)
      .then(({ documentNo }) => {
        this.showNotifSuccess("notif_create_success", {
          documentNumber: documentNo,
        });
        this.fetchDetail(this.store.form.id);
      })
      .finally(() => {
        row.loading.settle = false;
      });
  }

  isAllowCreatePayment(row: RowPeriod): boolean {
    const { status } = this.store.form;
    const havntPaid = !row.cashInOutId;
    return (
      havntPaid &&
      status.toUpperCase() === LeasingStateEnum.SUBMITTED.toUpperCase()
    );
  }

  handleGenerate(): void {
    const form: Partial<FormValue> = {
      deletedPeriodLineIds: this.store.detail.periodList.map(row => row.id),
      periodList: [],
    };
    this.setForm(form);
    this.generatePeriod();
  }

  fetchPaymentType(): void {
    this.loading = true;
    useFindMasterType("LEASING_PAYMENT_TYPE")
      .then(res => {
        this.paymentTypeOptions = useMapMasterTypeToOptionAlt(res);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  mounted() {
    this.fetchPaymentType();
  }
}
