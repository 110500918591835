







































































































































































































































































































































































import SelectAccountChild from "@/components/custom/select/SelectAccountChild.vue";
import SelectBranch from "@/components/custom/select/SelectBranch.vue";
import SelectCompanyBank from "@/components/custom/select/SelectCompanyBank.vue";
import SelectCurrency from "@/components/custom/select/SelectCurrency.vue";
import SelectDealer from "@/components/custom/select/SelectDealer.vue";
import SelectInsurance from "@/components/custom/select/SelectInsurance.vue";
import SelectLessor from "@/components/custom/select/SelectLessor.vue";
import { DisplayBoolean } from "@/components/DisplayBoolean";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { ONE } from "@/models/constant/global.constant";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { FormValue, State } from "@/store/leasing.store";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { AccountingAccountResponseDto } from "@interface/accounting-account";
import { ListCompanyBankDto } from "@interface/company-bank";
import { ListContactDataDto } from "@interface/contact-data";
import { DataWarehouseBranch } from "@interface/logistic.interface";
import { AccountingCurrencyResponseDTO } from "@interface/settings.interface";
import { FormModel } from "ant-design-vue";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import FieldPolicyNumber from "./FieldPolicyNumber.vue";

@Component({
  components: {
    SelectBranch,
    SelectDealer,
    SelectLessor,
    SelectCurrency,
    DisplayBoolean,
    SelectAccountChild,
    SelectInsurance,
    SelectCompanyBank,
    FieldPolicyNumber,
  },
  computed: {
    ...mapState({
      store: (st: any) => st.leasingStore,
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
    ...mapGetters({
      isIdr: "leasingStore/isIdr",
      isDocExist: "leasingStore/isDocExist",
      disabledForm: "leasingStore/disabledForm",
    }),
  },
  methods: {
    ...mapMutations({
      setForm: "leasingStore/setForm",
    }),
    ...mapActions({
      calcUnitPricing: "leasingStore/calcUnitPricing",
      calcLeasingResidue: "leasingStore/calcLeasingResidue",
      calcTotalInstallment: "leasingStore/calcTotalInstallment",
    }),
  },
})
export default class FormHeader extends Mixins(MNotificationVue) {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  formatterNumber = formatterNumber;
  reverseFormatNumber = reverseFormatNumber;

  storeBaseDecimalPlace!: number;
  store!: State;
  isIdr!: boolean;
  isDocExist!: boolean;
  disabledForm!: boolean;
  setForm!: (payload: Partial<FormValue>) => void;
  calcUnitPricing!: () => void;
  calcLeasingResidue!: () => void;
  calcTotalInstallment!: () => void;

  @Ref("formModel")
  formModel!: FormModel;

  formRules = {
    branchName: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    dealerName: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    lessorName: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    leasingContractDate: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    leasingContractNumber: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    leasingPeriod: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    currencyName: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    rates: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    startPaymentDate: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    principalCostAccountCode: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    interestCostAccountCode: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    nettFinance: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    taxTotalAmount: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    costPrice: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    interest: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    provision: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    residue: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    insuranceName: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    insurancePremium: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    insurancePeriod: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    bankName: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    notes: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
  };

  onChangeBranch(val?: Option<DataWarehouseBranch>): void {
    this.setForm({
      branchName: val?.label || "",
      branchId: val?.meta?.id || "",
    });
  }

  onChangeDealer(val?: Option<ListContactDataDto>): void {
    this.setForm({
      dealerName: val?.label || "",
      dealerId: val?.meta?.id || "",
      assetList: [],
    });
  }

  onChangeLessor(val?: Option<ListContactDataDto>): void {
    const billAddress: string =
      val?.meta?.addressDataList.find(item => item.billTo && item.primaryBillTo)
        ?.address || "";
    this.setForm({
      lessorName: val?.label || "",
      lessorId: val?.meta?.id || "",
      billingAddressLessor: billAddress,
    });
  }

  onChangeCurrency(val?: Option<AccountingCurrencyResponseDTO>): void {
    this.setForm({
      currencyId: val?.meta?.id || "",
      currencyName: val?.label || "",
      rates: ONE,
    });
  }

  onChangePrincipalAcc(value?: Option<AccountingAccountResponseDto>): void {
    this.setForm({
      principalCostAccountCode: value?.label || "",
      principalCostAccountId: value?.meta?.id || "",
    });
  }

  onChangeInterestAcc(value?: Option<AccountingAccountResponseDto>): void {
    this.setForm({
      interestCostAccountCode: value?.label || "",
      interestCostAccountId: value?.meta?.id || "",
    });
  }

  onChangeInsurance(value?: Option<ListContactDataDto>): void {
    this.setForm({
      insuranceName: value?.label || "",
      insuranceId: value?.meta?.id || "",
    });
  }

  onChangeBank(value?: Option<ListCompanyBankDto>): void {
    this.setForm({
      bankName: value?.label || "",
      companyBankId: value?.meta?.id || "",
    });
  }

  onChangeNettFinance(): void {
    this.calcLeasingResidue();
    this.calcUnitPricing();
  }

  onChangeCostPrice(): void {
    this.calcTotalInstallment();
    this.calcLeasingResidue();
    this.calcUnitPricing();
  }

  onChangeInterest(): void {
    this.calcTotalInstallment();
    this.calcUnitPricing();
  }
}
