var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          size: "small",
          "data-source": _vm.store.form.assetList,
          columns: _vm.columns,
          scroll: { x: "calc(100% + 3000px)", y: 600 },
          pagination: false,
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "nullable",
              fn: function(text) {
                return [_vm._v(" " + _vm._s(text || "-") + " ")]
              }
            },
            {
              key: "currency",
              fn: function(text) {
                return [_vm._v(" " + _vm._s(_vm._f("currency")(text)) + " ")]
              }
            },
            !_vm.disabledForm
              ? {
                  key: "title",
                  fn: function() {
                    return [
                      _c(
                        "a-button-group",
                        [
                          _c("a-button", {
                            attrs: {
                              icon: "plus",
                              type: "primary",
                              disabled: !_vm.store.form.dealerId
                            },
                            on: { click: _vm.addUnit }
                          }),
                          _c("a-button", {
                            attrs: { icon: "delete", type: "danger" },
                            on: { click: _vm.handleRemoveUnit }
                          })
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              : null,
            {
              key: "assetUnitCode",
              fn: function(text, row) {
                return [
                  _vm.disabledForm
                    ? [
                        _c("span", [
                          _vm._v(" " + _vm._s(row.assetUnitCode) + " ")
                        ])
                      ]
                    : _c("SelectUnitLeasing", {
                        staticClass: "w-100",
                        attrs: { "dealer-id": _vm.store.form.dealerId },
                        on: {
                          "update:meta": function(e) {
                            return _vm.onChangeUnit(e, row)
                          }
                        },
                        model: {
                          value: row.assetUnitCode,
                          callback: function($$v) {
                            _vm.$set(row, "assetUnitCode", $$v)
                          },
                          expression: "row.assetUnitCode"
                        }
                      })
                ]
              }
            },
            {
              key: "paymentTaxInvoiceNumber",
              fn: function(text, row) {
                return [
                  _c("a-input", {
                    attrs: { "allow-clear": "", disabled: _vm.disabledForm },
                    model: {
                      value: row.paymentTaxInvoiceNumber,
                      callback: function($$v) {
                        _vm.$set(row, "paymentTaxInvoiceNumber", $$v)
                      },
                      expression: "row.paymentTaxInvoiceNumber"
                    }
                  })
                ]
              }
            },
            {
              key: "paymentTaxInvoiceDate",
              fn: function(text, row) {
                return [
                  _c("a-date-picker", {
                    attrs: {
                      format: _vm.DEFAULT_DATE_FORMAT,
                      disabled: _vm.disabledForm
                    },
                    model: {
                      value: row.paymentTaxInvoiceDate,
                      callback: function($$v) {
                        _vm.$set(row, "paymentTaxInvoiceDate", $$v)
                      },
                      expression: "row.paymentTaxInvoiceDate"
                    }
                  })
                ]
              }
            },
            {
              key: "portion",
              fn: function(text, row) {
                return [
                  _c("a-input-number", {
                    staticClass: "w-100",
                    attrs: {
                      parser: _vm.reverseFormatPercent,
                      formatter: _vm.formatterPercent,
                      precision: _vm.storeBaseDecimalPlace,
                      disabled: _vm.disabledForm
                    },
                    model: {
                      value: row.portion,
                      callback: function($$v) {
                        _vm.$set(row, "portion", _vm._n($$v))
                      },
                      expression: "row.portion"
                    }
                  })
                ]
              }
            },
            {
              key: "costPricePerUnit",
              fn: function(text, row) {
                return [
                  _c("a-input-number", {
                    staticClass: "w-100",
                    attrs: {
                      parser: _vm.reverseFormatNumber,
                      formatter: _vm.formatterNumber,
                      precision: _vm.storeBaseDecimalPlace,
                      disabled: _vm.disabledForm
                    },
                    on: { change: _vm.onChangeCostPriceUnit },
                    model: {
                      value: row.costPricePerUnit,
                      callback: function($$v) {
                        _vm.$set(row, "costPricePerUnit", _vm._n($$v))
                      },
                      expression: "row.costPricePerUnit"
                    }
                  })
                ]
              }
            }
          ],
          null,
          true
        )
      }),
      _c("div", { staticClass: "mt-2 text-right" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("lbl_total_items", {
                total: _vm.store.form.assetList.length
              })
            ) +
            " "
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }