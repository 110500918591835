var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form-model",
    {
      ref: "formModel",
      attrs: {
        model: _vm.store.form,
        rules: _vm.formRules,
        "label-align": "left",
        "wrapper-col": { span: 12 },
        "label-col": { span: 9 }
      }
    },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c(
                "a-form-model-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.store.form.leasingNumber,
                      expression: "store.form.leasingNumber"
                    }
                  ],
                  attrs: { label: _vm.$t("lbl_leasing_number") }
                },
                [
                  _c("a-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.store.form.leasingNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.store.form, "leasingNumber", $$v)
                      },
                      expression: "store.form.leasingNumber"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t("lbl_branch"), prop: "branchName" } },
                [
                  _c("SelectBranch", {
                    attrs: { disabled: _vm.disabledForm },
                    on: { "update:meta": _vm.onChangeBranch },
                    model: {
                      value: _vm.store.form.branchName,
                      callback: function($$v) {
                        _vm.$set(_vm.store.form, "branchName", $$v)
                      },
                      expression: "store.form.branchName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_dealer_name"),
                    prop: "dealerName"
                  }
                },
                [
                  _c("SelectDealer", {
                    attrs: { disabled: _vm.disabledForm },
                    on: { "update:meta": _vm.onChangeDealer },
                    model: {
                      value: _vm.store.form.dealerName,
                      callback: function($$v) {
                        _vm.$set(_vm.store.form, "dealerName", $$v)
                      },
                      expression: "store.form.dealerName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_lessor_name"),
                    prop: "lessorName"
                  }
                },
                [
                  _c("SelectLessor", {
                    attrs: { disabled: _vm.disabledForm },
                    on: { "update:meta": _vm.onChangeLessor },
                    model: {
                      value: _vm.store.form.lessorName,
                      callback: function($$v) {
                        _vm.$set(_vm.store.form, "lessorName", $$v)
                      },
                      expression: "store.form.lessorName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_lessor_billing_address"),
                    prop: "billingAddressLessor"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "extra",
                      fn: function() {
                        return [
                          _c("character-length", {
                            attrs: {
                              value: _vm.store.form.billingAddressLessor
                            }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("a-textarea", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.store.form.billingAddressLessor,
                      callback: function($$v) {
                        _vm.$set(_vm.store.form, "billingAddressLessor", $$v)
                      },
                      expression: "store.form.billingAddressLessor"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_leasing_contract_date"),
                    prop: "leasingContractDate"
                  }
                },
                [
                  _c("a-date-picker", {
                    attrs: {
                      format: _vm.DEFAULT_DATE_FORMAT,
                      disabled: _vm.disabledForm
                    },
                    model: {
                      value: _vm.store.form.leasingContractDate,
                      callback: function($$v) {
                        _vm.$set(_vm.store.form, "leasingContractDate", $$v)
                      },
                      expression: "store.form.leasingContractDate"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_leasing_contract_number"),
                    prop: "leasingContractNumber"
                  }
                },
                [
                  _c("a-input", {
                    attrs: { "allow-clear": "", disabled: _vm.disabledForm },
                    model: {
                      value: _vm.store.form.leasingContractNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.store.form, "leasingContractNumber", $$v)
                      },
                      expression: "store.form.leasingContractNumber"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: { label: _vm.$t("lbl_currency"), prop: "currencyName" }
                },
                [
                  _c("SelectCurrency", {
                    attrs: { disabled: _vm.disabledForm },
                    on: { "update:meta": _vm.onChangeCurrency },
                    model: {
                      value: _vm.store.form.currencyName,
                      callback: function($$v) {
                        _vm.$set(_vm.store.form, "currencyName", $$v)
                      },
                      expression: "store.form.currencyName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isIdr,
                      expression: "!isIdr"
                    }
                  ],
                  attrs: { label: _vm.$t("lbl_currency_rate"), prop: "rates" }
                },
                [
                  _c("a-input-number", {
                    attrs: {
                      parser: _vm.reverseFormatNumber,
                      formatter: _vm.formatterNumber,
                      precision: _vm.storeBaseDecimalPlace,
                      min: 1,
                      disabled: _vm.disabledForm
                    },
                    model: {
                      value: _vm.store.form.rates,
                      callback: function($$v) {
                        _vm.$set(_vm.store.form, "rates", _vm._n($$v))
                      },
                      expression: "store.form.rates"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isDocExist,
                      expression: "isDocExist"
                    }
                  ],
                  attrs: { label: _vm.$t("lbl_paid_off") }
                },
                [
                  _c("DisplayBoolean", {
                    attrs: { value: _vm.store.form.paidOff, mode: "yes" }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isDocExist,
                      expression: "isDocExist"
                    }
                  ],
                  attrs: { label: _vm.$t("lbl_paid_off_date") }
                },
                [
                  _c("a-date-picker", {
                    attrs: { disabled: "", format: _vm.DEFAULT_DATE_FORMAT },
                    model: {
                      value: _vm.store.form.paidOffDate,
                      callback: function($$v) {
                        _vm.$set(_vm.store.form, "paidOffDate", $$v)
                      },
                      expression: "store.form.paidOffDate"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isDocExist,
                      expression: "!isDocExist"
                    }
                  ],
                  attrs: { label: _vm.$t("lbl_cheque") }
                },
                [
                  _c("a-switch", {
                    attrs: { disabled: _vm.disabledForm },
                    model: {
                      value: _vm.store.form.useCheque,
                      callback: function($$v) {
                        _vm.$set(_vm.store.form, "useCheque", $$v)
                      },
                      expression: "store.form.useCheque"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.store.form.useCheque,
                      expression: "store.form.useCheque"
                    }
                  ],
                  attrs: { label: _vm.$t("lbl_cheque_number") }
                },
                [
                  _c("a-input", {
                    attrs: { disabled: _vm.disabledForm, "allow-clear": "" },
                    model: {
                      value: _vm.store.form.chequeNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.store.form, "chequeNumber", $$v)
                      },
                      expression: "store.form.chequeNumber"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_leasing_period"),
                    prop: "leasingPeriod"
                  }
                },
                [
                  _c("a-input-number", {
                    attrs: { disabled: _vm.disabledForm },
                    model: {
                      value: _vm.store.form.leasingPeriod,
                      callback: function($$v) {
                        _vm.$set(_vm.store.form, "leasingPeriod", _vm._n($$v))
                      },
                      expression: "store.form.leasingPeriod"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_start_payment_date"),
                    prop: "startPaymentDate"
                  }
                },
                [
                  _c("a-date-picker", {
                    attrs: {
                      format: _vm.DEFAULT_DATE_FORMAT,
                      disabled: _vm.disabledForm
                    },
                    model: {
                      value: _vm.store.form.startPaymentDate,
                      callback: function($$v) {
                        _vm.$set(_vm.store.form, "startPaymentDate", $$v)
                      },
                      expression: "store.form.startPaymentDate"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_principal_cost_account"),
                    prop: "principalCostAccountCode"
                  }
                },
                [
                  _c("SelectAccountChild", {
                    attrs: { disabled: _vm.disabledForm },
                    on: { "update:meta": _vm.onChangePrincipalAcc },
                    model: {
                      value: _vm.store.form.principalCostAccountCode,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.store.form,
                          "principalCostAccountCode",
                          $$v
                        )
                      },
                      expression: "store.form.principalCostAccountCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_interest_cost_account"),
                    prop: "interestCostAccountCode"
                  }
                },
                [
                  _c("SelectAccountChild", {
                    attrs: { disabled: _vm.disabledForm },
                    on: { "update:meta": _vm.onChangeInterestAcc },
                    model: {
                      value: _vm.store.form.interestCostAccountCode,
                      callback: function($$v) {
                        _vm.$set(_vm.store.form, "interestCostAccountCode", $$v)
                      },
                      expression: "store.form.interestCostAccountCode"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_nett_finance_principal_cost"),
                    "label-align": "right",
                    prop: "nettFinance"
                  }
                },
                [
                  _c("a-input-number", {
                    staticClass: "w-100",
                    attrs: {
                      parser: _vm.reverseFormatNumber,
                      formatter: _vm.formatterNumber,
                      precision: _vm.storeBaseDecimalPlace,
                      disabled: _vm.disabledForm
                    },
                    on: { change: _vm.onChangeNettFinance },
                    model: {
                      value: _vm.store.form.nettFinance,
                      callback: function($$v) {
                        _vm.$set(_vm.store.form, "nettFinance", _vm._n($$v))
                      },
                      expression: "store.form.nettFinance"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_tax_total_amount"),
                    "label-align": "right",
                    prop: "taxTotalAmount"
                  }
                },
                [
                  _c("a-input-number", {
                    staticClass: "w-100",
                    attrs: {
                      parser: _vm.reverseFormatNumber,
                      formatter: _vm.formatterNumber,
                      precision: _vm.storeBaseDecimalPlace,
                      disabled: _vm.disabledForm
                    },
                    on: { change: _vm.calcUnitPricing },
                    model: {
                      value: _vm.store.form.taxTotalAmount,
                      callback: function($$v) {
                        _vm.$set(_vm.store.form, "taxTotalAmount", _vm._n($$v))
                      },
                      expression: "store.form.taxTotalAmount"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_cost_price"),
                    "label-align": "right",
                    prop: "costPrice"
                  }
                },
                [
                  _c("a-input-number", {
                    staticClass: "w-100",
                    attrs: {
                      parser: _vm.reverseFormatNumber,
                      formatter: _vm.formatterNumber,
                      precision: _vm.storeBaseDecimalPlace,
                      disabled: _vm.disabledForm
                    },
                    on: { change: _vm.onChangeCostPrice },
                    model: {
                      value: _vm.store.form.costPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.store.form, "costPrice", _vm._n($$v))
                      },
                      expression: "store.form.costPrice"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_interest"),
                    "label-align": "right",
                    prop: "interest"
                  }
                },
                [
                  _c("a-input-number", {
                    staticClass: "w-100",
                    attrs: {
                      parser: _vm.reverseFormatNumber,
                      formatter: _vm.formatterNumber,
                      precision: _vm.storeBaseDecimalPlace,
                      disabled: _vm.disabledForm
                    },
                    on: { change: _vm.onChangeInterest },
                    model: {
                      value: _vm.store.form.interest,
                      callback: function($$v) {
                        _vm.$set(_vm.store.form, "interest", _vm._n($$v))
                      },
                      expression: "store.form.interest"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_total_installment"),
                    "label-align": "right",
                    prop: "total"
                  }
                },
                [
                  _c("a-input-number", {
                    staticClass: "w-100",
                    attrs: {
                      parser: _vm.reverseFormatNumber,
                      formatter: _vm.formatterNumber,
                      precision: _vm.storeBaseDecimalPlace,
                      disabled: ""
                    },
                    model: {
                      value: _vm.store.form.total,
                      callback: function($$v) {
                        _vm.$set(_vm.store.form, "total", _vm._n($$v))
                      },
                      expression: "store.form.total"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_provision"),
                    "label-align": "right",
                    prop: "provision"
                  }
                },
                [
                  _c("a-input-number", {
                    staticClass: "w-100",
                    attrs: {
                      parser: _vm.reverseFormatNumber,
                      formatter: _vm.formatterNumber,
                      precision: _vm.storeBaseDecimalPlace,
                      disabled: _vm.disabledForm
                    },
                    on: { change: _vm.calcUnitPricing },
                    model: {
                      value: _vm.store.form.provision,
                      callback: function($$v) {
                        _vm.$set(_vm.store.form, "provision", _vm._n($$v))
                      },
                      expression: "store.form.provision"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_residue"),
                    "label-align": "right",
                    prop: "residue"
                  }
                },
                [
                  _c("a-input-number", {
                    staticClass: "w-100",
                    attrs: {
                      parser: _vm.reverseFormatNumber,
                      formatter: _vm.formatterNumber,
                      precision: _vm.storeBaseDecimalPlace,
                      disabled: ""
                    },
                    model: {
                      value: _vm.store.form.residue,
                      callback: function($$v) {
                        _vm.$set(_vm.store.form, "residue", _vm._n($$v))
                      },
                      expression: "store.form.residue"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_insurance_name"),
                    "label-align": "right",
                    prop: "insuranceName"
                  }
                },
                [
                  _c("SelectInsurance", {
                    attrs: { disabled: _vm.disabledForm },
                    on: { "update:meta": _vm.onChangeInsurance },
                    model: {
                      value: _vm.store.form.insuranceName,
                      callback: function($$v) {
                        _vm.$set(_vm.store.form, "insuranceName", $$v)
                      },
                      expression: "store.form.insuranceName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_premium"),
                    "label-align": "right",
                    prop: "insurancePremium"
                  }
                },
                [
                  _c("a-input-number", {
                    staticClass: "w-100",
                    attrs: {
                      parser: _vm.reverseFormatNumber,
                      formatter: _vm.formatterNumber,
                      precision: _vm.storeBaseDecimalPlace,
                      disabled: _vm.disabledForm
                    },
                    on: { change: _vm.calcUnitPricing },
                    model: {
                      value: _vm.store.form.insurancePremium,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.store.form,
                          "insurancePremium",
                          _vm._n($$v)
                        )
                      },
                      expression: "store.form.insurancePremium"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_insurance_period"),
                    "label-align": "right",
                    prop: "insurancePeriod"
                  }
                },
                [
                  _c("a-input-number", {
                    attrs: {
                      parser: _vm.reverseFormatNumber,
                      formatter: _vm.formatterNumber,
                      precision: _vm.storeBaseDecimalPlace,
                      disabled: _vm.disabledForm
                    },
                    model: {
                      value: _vm.store.form.insurancePeriod,
                      callback: function($$v) {
                        _vm.$set(_vm.store.form, "insurancePeriod", _vm._n($$v))
                      },
                      expression: "store.form.insurancePeriod"
                    }
                  })
                ],
                1
              ),
              !!_vm.store.form.id
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_policy_no"),
                        "label-align": "right",
                        prop: "policyNumber"
                      }
                    },
                    [
                      _c("FieldPolicyNumber", {
                        attrs: { "leasing-id": _vm.store.form.id },
                        model: {
                          value: _vm.store.form.policyNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "policyNumber", $$v)
                          },
                          expression: "store.form.policyNumber"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_notes"),
                    "label-align": "right",
                    prop: "notes"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "extra",
                      fn: function() {
                        return [
                          _c("character-length", {
                            attrs: { value: _vm.store.form.notes }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("a-textarea", {
                    attrs: { disabled: _vm.disabledForm },
                    model: {
                      value: _vm.store.form.notes,
                      callback: function($$v) {
                        _vm.$set(_vm.store.form, "notes", $$v)
                      },
                      expression: "store.form.notes"
                    }
                  })
                ],
                1
              ),
              !!_vm.store.form.journalId
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_journal_number"),
                        "label-align": "right"
                      }
                    },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "generaljournal.detail",
                              params: { id: _vm.store.form.journalId }
                            }
                          }
                        },
                        [
                          _c("a-button", { attrs: { type: "link" } }, [
                            _vm._v(" " + _vm._s(_vm.store.form.journalNo) + " ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !!_vm.store.form.status
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_status"),
                        "label-align": "right"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.store.form.status,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "status", $$v)
                          },
                          expression: "store.form.status"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_bank_name"),
                    "label-align": "right",
                    prop: "bankName"
                  }
                },
                [
                  _c("SelectCompanyBank", {
                    attrs: { disabled: _vm.disabledForm },
                    on: { "update:meta": _vm.onChangeBank },
                    model: {
                      value: _vm.store.form.bankName,
                      callback: function($$v) {
                        _vm.$set(_vm.store.form, "bankName", $$v)
                      },
                      expression: "store.form.bankName"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }