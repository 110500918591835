


























































































import { useInvoiceAPPrepayment } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { InvoiceApPrepayment } from "@/models/interface/InvoiceApPrepayment.interface";
import { AssetLeasingResponseDto } from "@/models/interface/master-asset";
import { RowAsset, State } from "@/store/leasing.store";
import {
  formatterNumber,
  formatterPercent,
  reverseFormatNumber,
  reverseFormatPercent,
} from "@/validator/globalvalidator";
import { Component, Mixins } from "vue-property-decorator";
import { mapActions, mapGetters, mapState } from "vuex";
import SelectUnitLeasing from "./SelectUnitLeasing.vue";

@Component({
  components: {
    SelectUnitLeasing,
  },
  computed: {
    ...mapState({
      store: (st: any) => st.leasingStore,
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
    ...mapGetters({
      disabledForm: "leasingStore/disabledForm",
    }),
  },
  methods: {
    ...mapActions({
      addUnit: "leasingStore/addUnit",
      removeUnit: "leasingStore/removeUnit",
      calcUnitPricing: "leasingStore/calcUnitPricing",
    }),
  },
})
export default class FormUnitCode extends Mixins(MNotificationVue) {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  reverseFormatPercent = reverseFormatPercent;
  formatterPercent = formatterPercent;
  reverseFormatNumber = reverseFormatNumber;
  formatterNumber = formatterNumber;

  storeBaseDecimalPlace!: number;
  store!: State;
  disabledForm!: boolean;
  addUnit!: () => void;
  removeUnit!: (keys: Array<string>) => void;
  calcUnitPricing!: () => void;

  selectedRowKeys: Array<string> = [];

  columns = [
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "assetUnitCode",
      scopedSlots: { customRender: "assetUnitCode" },
    },
    {
      title: this.$t("lbl_tax_invoice_number_dp"),
      dataIndex: "taxInvoiceNumber",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_date"),
      dataIndex: "purchaseDate",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_tax_invoice_number_payment"),
      dataIndex: "paymentTaxInvoiceNumber",
      scopedSlots: { customRender: "paymentTaxInvoiceNumber" },
    },
    {
      title: this.$t("lbl_date_payment_invoice"),
      dataIndex: "paymentTaxInvoiceDate",
      scopedSlots: { customRender: "paymentTaxInvoiceDate" },
    },
    {
      title: this.$t("lbl_repayment_percentage"),
      dataIndex: "portion",
      scopedSlots: { customRender: "portion" },
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_brand"),
      dataIndex: "brand",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_cost_price_unit"),
      dataIndex: "costPricePerUnit",
      scopedSlots: { customRender: "costPricePerUnit" },
    },
    {
      title: this.$t("lbl_nett_finance"),
      dataIndex: "nettFinance",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_interest"),
      dataIndex: "interest",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_provision"),
      dataIndex: "provision",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_insurance"),
      dataIndex: "insurancePremium",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_residue"),
      dataIndex: "residue",
      scopedSlots: { customRender: "currency" },
    },
  ];

  handleRemoveUnit(): void {
    this.showConfirmationDeleteItems(() => {
      this.removeUnit(this.selectedRowKeys);
      this.selectedRowKeys = [];
    });
  }

  onSelectChange(keys: Array<string>): void {
    this.selectedRowKeys = keys;
  }

  onChangeUnit(
    e: Option<AssetLeasingResponseDto> | undefined,
    row: RowAsset
  ): void {
    row.assetId = e?.meta?.id || "";
    row.assetUnitCode = e?.label || "";
    row.serialNumber = e?.meta?.serialNumber || "";
    row.type = e?.meta?.type || "";
    row.brand = e?.meta?.model || "";
    row.costPricePerUnit = e?.meta?.receivePrice || 0;
    row.taxInvoiceNumber = "";
    row.purchaseDate = "";
    row.apPrepaymentInvoiceId = "";

    this.calcUnitPricing();

    if (!row.assetId) return;
    this.fetchPrepayment(row.assetId, res => {
      const invoices = res.filter(item => !!item.taxInvoiceNumber);
      const invoiceIds = invoices.map<string>(item => item.id).join(";");
      const taxInvoiceNumbers = invoices
        .map<string>(item => item.taxInvoiceNumber)
        .join(";");
      const taxInvoiceDates = invoices
        .map<string>(item => item.taxInvoiceDate)
        .join(";");

      row.taxInvoiceNumber = taxInvoiceNumbers;
      row.purchaseDate = taxInvoiceDates;
      row.apPrepaymentInvoiceId = invoiceIds;
    });
  }

  fetchPrepayment(
    unitId: string,
    cb: (res: Array<InvoiceApPrepayment>) => void
  ): void {
    const { findAllPrepaymentLeasing } = useInvoiceAPPrepayment();
    findAllPrepaymentLeasing(unitId).then(cb);
  }

  onChangeCostPriceUnit(): void {
    this.calcUnitPricing();
  }
}
